<template>
  <Page title="Log out?" class="text-center">
    <Button buttontype="background" @click="handleLogout">Log out</Button>
  </Page>
</template>

<script>
import Page from '@/components/page.vue'
import Button from '@/components/button.vue'
import { apiRequest } from '../components/js/utils'

export default {
  name: 'Logout',
  components: { Page, Button },
  methods: {
    async handleLogout () {
      try {
        await apiRequest.get('logout/')
        this.$store.commit('clearUser')
        this.$router.push('/')
        this.$store.commit('addAlert', {
          status: 'success',
          message: 'You have logged out',
          timer: 3000
        })
      } catch (e) {
        this.$store.commit('addAlert', {
          status: 'error',
          message: 'Something went wrong whilst attempting to log out. Please try again',
          timer: 4000
        })
      }
    }
  }
}
</script>
